import { TranslatableModel } from '@app/core/abstracts/translate-model'

export class Page<T = any> extends TranslatableModel<T> {
  id!: number
  slug!: string
}

export interface PageTranslationData {
  title: string
  key1: string
  key2: string
  scripts: string
}

export interface HomePageTranslationData {
  allNews: string
  eighthTrustItemDescription: string
  eighthTrustItemLink: string
  eighthTrustItemTitle: string
  eleventhTrustItemDescription: string
  eleventhTrustItemLink: string
  eleventhTrustItemTitle: string
  faqTitle: string
  fifthTrustItemDescription: string
  fifthTrustItemLink: string
  fifthTrustItemTitle: string
  firstTrustItemDescription: string
  firstTrustItemTitle: string
  firstTrustItemLink: string
  fourthTrustItemDescription: string
  fourthTrustItemLink: string
  fourthTrustItemTitle: string
  mainButtonLabel: string
  mainButtonUrl: string
  newsTitle: string
  ninthTrustItemDescription: string
  ninthTrustItemLink: string
  ninthTrustItemTitle: string
  secondTrustItemDescription: string
  secondTrustItemLink: string
  secondTrustItemTitle: string
  seventhTrustItemDescription: string
  seventhTrustItemLink: string
  seventhTrustItemTitle: string
  sixthTrustItemDescription: string
  sixthTrustItemLink: string
  sixthTrustItemTitle: string
  tenthTrustItemDescription: string
  tenthTrustItemLink: string
  tenthTrustItemTitle: string
  thirdTrustItemDescription: string
  thirdTrustItemLink: string
  thirdTrustItemTitle: string
  title: string
  trustServiceTitle: string
  twelfthTrustItemDescription: string
  twelfthTrustItemLink: string
  twelfthTrustItemTitle: string
}
