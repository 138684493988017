import { ApiService } from '@app/core/abstracts/api.service'
import { Page } from '@app/core/interfaces/page'
import { BehaviorSubject, Observable } from 'rxjs'

import { LangService } from './lang.service'

import { Injectable, inject } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class PageService extends ApiService {
  private langService: LangService = inject(LangService)
  commonTranslations$: BehaviorSubject<{ [key: string]: Page }> = new BehaviorSubject<{ [key: string]: Page }>({})

  get(slug: string): Observable<Page> {
    return this.getPage<Page>(`/front/page`, slug, (itemData: Page) => Page.fromJson<Page>(itemData, this.langService))
  }

  initTranlsation(slug: string): void {
    this.get(slug).subscribe((data) => {
      this.commonTranslations$.next({
        ...this.commonTranslations$.value,
        [slug]: data,
      })
    })
  }
}
